import React, { memo } from 'react'
import styles from './PageContentStyle.module.css'
const ReferencesContent = memo(() => {
  return (
    <div
      className={styles.contentContainer}
      style={{ width: '90%', borderColor: 'rgb(9, 163, 163)' }}
    >
      {' '}
      <h3>Modelling and Analysis by the Same Authors</h3>
      <p>
        <a href='https://www.powerfactbook.com/downloads/energy-reports/snowy-2-0-and-beyond-the-value-of-large-scale-energy-storage/download'>
          {' '}
          Snowy 2.0 and Beyond: The Value of Large-Scale Energy Storage (2020){' '}
        </a>
        This study has examined the impact of Snowy 2.0 and the Battery of The
        Nation, as well as scenarios beyond these two projects, to examine what
        benefit large scale pumped hydro storage could provide to the NEM as it
        decarbonises.
        <a href='https://www.powerfactbook.com/downloads/energy-reports/what-happens-when-we-add-big-infrastructure-to-the-nem/download'>
          {' '}
          What Happens When We Add Big Infrastructure To The NEM? (2020){' '}
        </a>
        The purpose of this study was to examine the effect of four substantial
        upgrades and how they may impact the shape of the anticipated
        decarbonisation transformation.
        <a href='https://www.powerfactbook.com/downloads/energy-reports/effect-of-ret-s-on-the-nem-2018/download'>
          {' '}
          Effect of RET's on the NEM (2018){' '}
        </a>
        Most Australian states and territories have relied on coal fired power
        for their electricity supplies. More recently, Qld, Vic, SA and ACT have
        adopted policies to increase their electricity from renewable energy and
        targeting net zero emissions aspirations. How this impacts on the
        physical operation of the NEM has been examined in this work, taking
        account of the enduring need for grid strength and reliability services.
        <a href='https://www.powerfactbook.com/downloads/energy-reports/score-sheet/download'>
          {' '}
          Score Sheet - the NEM (2019){' '}
        </a>
        How much does a technology actually cost to add to a power grid? Well
        that depends. On the Australian National Energy Market - the System Cost
        of Replacement Electricity is described using the NEW and exciting
        method.
        <a href='https://www.powerfactbook.com/downloads/energy-reports/managing-flexibility-whilst-decarbonising-electricity-full-report/download'>
          {' '}
          Managing Flexibility Whilst Decarbonising Electricity (2017){' '}
        </a>
         The National Electricity Market has embarked upon a pathway to a
        gradual decarbonisation, driven at State and Federal Governments and
        some by commitments made in Paris. This report gives insights into a new
        modelling approach and initial results to encourage a different energy
        conversation.
      </p>
      <h2>UK Work</h2>
      <p>
        <a href='https://erpuk.org/project/managing-flexibility-of-the-electricity-sytem/'>
          {' '}
          Managing Flexibility Whilst Decarbonising the GB Electricity System
          (ERP 2015){' '}
        </a>
         Considers increasing intermittent generation in amongst nuclear and CCS
        and the flexibility needed by the system operator.
        <a href='https://pubs.rsc.org/en/content/articlepdf/2018/ee/c7ee02342a'>
          {' '}
          Carbon capture and storage (CCS): the way forward,{' '}
          <em>Energy Environ. Sci., </em>2018, 11, 1062{' '}
        </a>
        Section 3.2 Integration of CCS into the electricity system - A modelling
        assessment of CCS in the context of the decarbonisation of the UK
        electricity system
      </p>
    </div>
  )
})

export default ReferencesContent
