import React, { memo } from 'react';
import styles from './FooterControls.module.css';
import ButtonTogglePair from './ButtonTogglePair/ButtonTogglePair';
import { isMobile, isTablet } from 'react-device-detect';

const FooterControls = memo(
  ({
    setDataRequested,
    dataRequested,
    setIncrementAmmount,
    incrementAmmount
  }) => {
    return (
      <>
        <div className={styles.buttonRow}>
          <ButtonTogglePair
            valueToUpdate={'country'}
            options={[
              { label: 'UK', value: 'uk' },
              { label: 'AUS', value: 'aus' }
            ]}
            value={dataRequested}
            setValue={setDataRequested}
            getNewDBData={true}
          />
          <div className={styles.tooltip}>
            <ButtonTogglePair
              valueToUpdate={'year'}
              options={[
                { label: '2020', value: 2020 },
                { label: '2050', value: 2050 }
              ]}
              value={dataRequested}
              setValue={setDataRequested}
              getNewDBData={true}
            />
            <span className={styles.tooltiptext}>
              <p>
                <b>2020 </b> <br />
                Reset to current capacities and adjust to create your 2020s
                scenario.
                <br />
                <br />
                <b>2050 </b> <br />
                Start with a clean sheet and plan a scenario for the 2050s.
              </p>
            </span>
          </div>
          {isMobile && !isTablet ? (
            <ButtonTogglePair
              valueToUpdate={'incrementBy'}
              options={[
                { label: '0.1GW', value: 0.1 },
                { label: '1GW', value: 1 }
              ]}
              value={incrementAmmount}
              setValue={setIncrementAmmount}
            />
          ) : null}
        </div>
      </>
    );
  }
);

export default FooterControls;
