import React, { memo } from 'react'
import styles from './PageContentStyle.module.css'
const EnergyGeeksContent = memo(() => {
  return (
    <div
      className={styles.contentContainer}
      style={{ width: '90%', borderColor: 'rgb(33, 134, 175)' }}
    >
      {' '}
      <p>
        The purpose of this website is to demonstrate some of the basic
        principles of good electricity system modelling, in a fun environment
        that should bring out broad brush principles.
      </p>
      <p>
        It is NOT intended as a tool for detailed analysis or system planning.
        However, if understood well, it is sufficiently robust to enable a user
        to ask the right questions about decarbonisation and to better
        interrogate results from other models advocated in the electricity
        system literature.
      </p>
      <p>
        A system planner or policy maker will need access to much more detailed
        models, such as MEGS which has been developed by the authors of this
        website. This website model embodies some fundamental principles which
        should be part of any serious modelling exercise:
        <ul>
          <li>
            <strong>Energy balances:</strong> Most models obey the principle of
            the conservation of energy across the year (although I can think of
            one or two exceptions!). However, they don’t all look at the shorter
            timescales and even if they do it is tempting to pick just average
            days, and avoid difficult hours and days. This website model
            schedules generation to meet demand at 64 time slices across the
            year. Robust modelling will use many more than this, for example
            MEGS is often run for {'>'}3,000 time steps per year, and it applies
            the conservation of energy separately for each interconnected region
            being modelled.
          </li>
          <li>
            <strong>Key constraints are observed:</strong> For example this
            model ensures that generation doesn’t exceed capacity x
            availability. It ensures that within each day energy consumed by a
            battery is the same as that delivered + losses. Other models such as
            MEGS have constraints on inter-regional transfers, inertia and
            reserve provision, but these are beyond this simple website model.
          </li>
          <li>
            <strong>The lights stay on:</strong> This model doesn’t guarantee
            this, but it makes it very clear when you have failed to keep the
            lights on. This model only checks whether there is sufficient
            capacity to meet demand. In reality keeping the lights on is much
            more complex and professional models will consider other
            requirements. For example, MEGS ensures that for each region there
            is a minimum level of inertia at all points in time, and there is
            sufficient headroom to provide reserve and frequency response.
          </li>
          <li>
            <strong>Carbon emissions are reported:</strong> As most energy
            systems aim to reduce carbon emissions it is important that the
            effect of changing model inputs on emissions is reported clearly.
            Most modelling exercises have emission reduction as a fundamental
            objective so this is usually reported.
          </li>
          <li>
            <strong>Total System Cost is reported:</strong> This is of
            fundamental importance to policy makers and feeds directly into the
            energy price that consumers see. However, reporting this is often
            neglected in favour of comparing the capex and opex of various
            generation options and quoting some sort of ratio of cost to energy
            production. Although an investor may find this useful when
            negotiating a power purchase agreement, it is of little use to
            anyone planning an energy system because many of the costs (and
            indeed benefits) of a technology occur in the wider system. Also, it
            says nothing about the value of the energy delivered which can vary
            wildly between technologies nor the value of grid services provided.
          </li>
          <li>
            <strong>Transparency:</strong> This is a tricky area because some
            models are proprietary and are therefore black boxes. Others are
            completely open source, which is probably ideal but the loss of IP
            can be daunting for a developer that has invested a lot of time in a
            tool they hope to recover their investment from. Anyway, there is
            nothing secret about this website model. You can view the code on
            <a
              href='https://github.com/dominicboston/ModellingEnergyAlgorithm'
              rel='noopener noreferrer'
              target='_blank'
            >
              GitHub by following this link.
            </a>
            The authors of this website are working on a paper aimed at a peer
            reviewed journal to lift the lid on the inner workings of MEGS.
          </li>
          <li>
            <strong>Net Zero by 2050:</strong> A 2050 option is provided as a
            blank sheet of paper for you to build your electricity system. It
            starts with zero technology assets because it assumes all plant
            running in 2050 will most likely be “new build”. Nearly all existing
            “current assets” would have reached their “end-of-life” and been
            retired by then.{' '}
          </li>
        </ul>
      </p>
    </div>
  )
})

export default EnergyGeeksContent
