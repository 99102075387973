import React, { memo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styles from './LandingPage.module.css';
import { CircleHeading } from './CircleHeading/CircleHeading';
import { ESXSegment } from './Segment/ESXSegment';
import { MEGSSegment } from './Segment/MEGSSegment';
import { PublicationsSegment } from './Segment/PublicationsSegment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faFacebookSquare,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';

const LandingPage = memo(() => {
  const circleHeadingData = [
    {
      id: 1,
      link: '#ESx',
      img: './images/landingPage/circles/simpleVersion.webp',
      title: 'ESx',
      description:
        'Easy-to-use Electricity Scenario Explorer allows you to quickly test different generation scenarios in your browser. It models your scenario for 8 sample days calculating changes to cost, CO2 emissions and ability to meet demand.'
    },
    {
      id: 2,
      link: '#MEGS',
      img: './images/landingPage/circles/megs.png',
      title: 'MEGS',
      description:
        'A sophisticated Model of Energy and Grid Services, has been used to derive more than 3,000 scenarios for a low carbon National Electricity Market in Australia. You can access the results here, filtering out scenarios by technology constraints and seeing the effect on Total System Cost.'
    },
    {
      id: 3,
      link: '#publications',
      img: './images/landingPage/circles/publications.png',
      title: 'Publications',
      description:
        'Red Vector and Gamma Energy Technology are keen that at much as possible of their research is publicly available, none of it is hidden behind a paywall. Feel free to browse and download publications here.'
    }
  ];

  const [showMoreAbout, setShowMoreAbout] = useState();
  return (
    <div className={styles.landingPageWrapper}>
      <div className={styles.socials}>
        <FontAwesomeIcon
          className={styles.socialIcons}
          icon={faFacebookSquare}
          onClick={() =>
            window.open('https://www.facebook.com/Modelling.Energy/', '_black')
          }
        />
        <FontAwesomeIcon
          className={styles.socialIcons}
          icon={faLinkedin}
          onClick={() =>
            window.open(
              'https://www.linkedin.com/showcase/megs-model',
              '_black'
            )
          }
        />
      </div>
      <div className={styles.welcomeContainer}>
        <div className={styles.contentContainer}>
          <h1> Welcome to Modelling.Energy</h1>
          <h3>Develop an understanding of the Electricity System</h3>

          <div className={styles.circleContainer}>
            {circleHeadingData.map((chd) => (
              <CircleHeading
                link={chd.link}
                img={chd.img}
                title={chd.title}
                description={chd.description}
              />
            ))}
          </div>
          <p style={!showMoreAbout ? {} : { height: '100%' }}>
            {!showMoreAbout && (
              <div
                onClick={() => setShowMoreAbout(true)}
                className={styles.aboutUsShadow}
              ></div>
            )}
            <span>
              This resource has been assembled for the benefit of anyone seeking
              to understand how we can clean up the electricity system. It is
              based on work by{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.redvector.co.uk'
              >
                Red Vector Ltd
              </a>
              , a UK based energy consultancy founded by Andy Boston, and{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.gamma-energy-technology.com.au'
              >
                Gamma Energy Technology
              </a>
              , based in Australia and founded by Geoff Bongers. There are three
              elements, ESX allows anyone to have a go at designing their own
              power system and is ideal for anyone with little understanding of
              electricity, and yet has sufficient realism for the more
              experienced to feel confident in its representation. The second
              section reports on results from MEGS modelling of 3,000+
              decarbonisation scenarios for Australia allowing users to see the
              effect of technology availability constraints on the cost of
              decarbonisation. Finally more than 10 reports, papers and other
              publications based on the Red Vector’s and GET's work can be
              accessed in the bibliography. <br />
              <br />
              You can also find out more on
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.linkedin.com/showcase/19234180'
              >
                {' '}
                LinkedIn
              </a>{' '}
              and{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.facebook.com/ModellingEnergy-106193661299302'
              >
                Facebook
              </a>{' '}
            </span>
          </p>
        </div>
      </div>

      <ESXSegment />
      <MEGSSegment />
      <PublicationsSegment />
    </div>
  );
});

export default LandingPage;
