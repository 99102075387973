import {
  faBars,
  faChalkboardTeacher,
  faChartArea,
  faHome
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styles from './Controls.module.css';

export const ControlHeader = ({
  setNavRequired,
  handleVersionSwitch,
  dataRequested,
  version
}) => {
  return (
    <div className={styles.controlHeader}>
      <Link to='/'>
        <FontAwesomeIcon icon={faHome} />
      </Link>

      <h1 id={styles.pageTitle} style={{ margin: 0, fontSize: 1.5 + 'em' }}>
        {dataRequested.country} {dataRequested.year}
      </h1>

      {dataRequested.dataset !== 'megs' && (
        <FontAwesomeIcon
          icon={version === 'educational' ? faChartArea : faChalkboardTeacher}
          className={styles.navIcon}
          onClick={() => {
            handleVersionSwitch();
          }}
        />
      )}

      <FontAwesomeIcon
        icon={faBars}
        className={styles.navIcon}
        onClick={() => {
          setNavRequired((prevState) => !prevState);
        }}
      />
    </div>
  );
};
