import React, { memo, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'
import styles from './PageContentStyle.module.css'
import formStyles from './ContactUs.module.css'
import classNames from 'classnames'
const ContactUsContent = memo(() => {
  const [result, setResult] = useState(null)
  const [reportABug, setReportABug] = useState(false)
  const [sending, setSending] = useState(false)
  const [state, setState] = useState({
    name: '',
    email: '',
    security: '',
    subject: 'Submission from Modelling.Energy Contact Form',
    message: ''
  })
  const [validInput, setValidInput] = useState({
    name: false,
    email: false,
    security: false,
    message: false
  })

  const sendEmail = event => {
    event.preventDefault()
    if (
      validInput.name &&
      validInput.email &&
      validInput.security &&
      validInput.message
    ) {
      setSending(true)
      axios
        .post('https://server.modelling.energy/send', {
          ...state,
          userSettings: `width: ${window.innerWidth} height:${window.innerHeight} link: ${window.location.href}`
        })
        .then(response => {
          setResult(response.data)
          setState({
            name: '',
            email: '',
            security: '',
            subject: 'Submission from Modelling.Energy Contact Form',
            message: ''
          })
          setValidInput({
            name: false,
            email: false,
            security: false,
            message: false
          })
        })
        .then(() => {
          setSending(false)
        })
        .catch(error => {
          console.log(error)
          setResult({
            success: false,
            message: 'Something went wrong. Try again later'
          })
          setSending(false)
        })
    } else {
      setResult({
        success: false,
        message: 'Please check all inputs are valid (green) and try again'
      })
    }
  }
  const onInputChange = event => {
    setResult(null)
    const { name, value } = event.target
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    )

    if (value.length > 1 && name !== 'email' && name !== 'security') {
      setValidInput({ ...validInput, [name]: true })
    } else if (name === 'email' && pattern.test(value)) {
      setValidInput({ ...validInput, [name]: true })
    } else if (
      (name === 'security' && value === '9') ||
      value.toLowerCase() === 'nine'
    ) {
      setValidInput({ ...validInput, [name]: true })
    } else {
      setValidInput({ ...validInput, [name]: false })
    }

    setState({
      ...state,
      [name]: value
    })
  }

  return (
    <div className={styles.contentContainer} style={{ borderColor: '#d62222' }}>
      <form onSubmit={sendEmail} className={formStyles.formContainer}>
        {reportABug ? (
          <>
            <h2 style={{ margin: '5px' }}>Report A Bug</h2>
            <p>
              Sorry you encountered a problem, report it below and we will
              capture your current settings.
            </p>{' '}
          </>
        ) : (
          <p>
            Feel free to send your comments through this form and we will get
            back to you, or post of social media an continue the conversation!
            You can follow us on{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.linkedin.com/showcase/19234180'
            >
              LinkedIn
            </a>{' '}
            and{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.facebook.com/ModellingEnergy-106193661299302'
            >
              Facebook
            </a>
            .
            <br /> If you would like to report a bug, please{' '}
            <span onClick={() => setReportABug(true)}>click here</span>.
          </p>
        )}

        <Form.Control
          style={
            validInput.name
              ? { borderColor: 'green' }
              : state.name.length > 0
              ? { borderColor: '#d62222' }
              : {}
          }
          className={formStyles.inputBox}
          type='text'
          name='name'
          value={state.name}
          placeholder='Full Name'
          onChange={onInputChange}
        />

        <Form.Control
          style={
            validInput.email
              ? { borderColor: 'green' }
              : state.email.length > 0
              ? { borderColor: '#d62222' }
              : {}
          }
          className={formStyles.inputBox}
          type='email'
          name='email'
          value={state.email}
          placeholder='Email'
          onChange={onInputChange}
        />

        <Form.Control
          style={validInput.security ? { borderColor: 'green' } : {}}
          className={formStyles.inputBox}
          type='text'
          name='security'
          value={state.security}
          placeholder='What is 3 squared?'
          onChange={onInputChange}
        />

        <Form.Control
          style={
            validInput.message
              ? { borderColor: 'green' }
              : state.message.length > 0
              ? { borderColor: '#d62222' }
              : {}
          }
          className={classNames([formStyles.message, formStyles.inputBox])}
          as='textarea'
          name='message'
          value={state.message}
          rows='3'
          placeholder={
            reportABug ? 'Please describle the bug and the cause' : 'Message'
          }
          onChange={onInputChange}
        />

        {sending ? (
          <p style={{ color: 'green' }}> Sending...</p>
        ) : (
          <Button
            className={formStyles.submitButton}
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
        )}
        {result && (
          <p
            className={`${
              result.success ? formStyles.formSucess : formStyles.formError
            }`}
          >
            {result.message}
          </p>
        )}
      </form>
    </div>
  )
})

export default ContactUsContent
