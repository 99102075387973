import { useState, useEffect, useCallback, useRef } from 'react';

import getDataFromDB from './components/getDataFromDB';

import styles from './Main.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import {
  AllInputDataContext,
  CalculatedDataContext,
  SliderValuesContext,
  SetSliderValuesContext,
  UserLinkContext
} from './config';
import Controls from './components/controls/Controls';
import MECharts from './components/MEcharts/MECharts';
import Nav from './components/nav/Nav';
import runCalculation from './algorithm/runCalculation';
import getQueryVariable from './functions/getQueryVariable';
import GenericPage from './pages/GenericPage';
import { MEGSCharts } from './components/MEGS/MEGSCharts';
import { updateAddressBar } from './functions/updateAddressBar';
import { useHistoryHandeller } from './useEffects/useHistoryHandeller';
import { useIsLandscape } from './useEffects/useIsLandscape';

/* eslint-disable import/no-webpack-loader-syntax */

// import worker from 'workerize-loader?inline!./algorithm/calculation.worker.js'
// const workerInstance = worker()

const {
  sliderValues: sliderValuesFromQuery = 'invalid',
  country: countryFromQuery = 'uk',
  year: yearFromQuery = 2020,
  version: versionFromQuery = 'educational',
  page: pageFromQuery = 'charts'
} = getQueryVariable();

const Main = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const allInputDataNEW = useRef({});
  const [calculatedData, setCalculatedData] = useState({});
  const [sliderValues, setSliderValues] = useState();
  const sliderValuesFromDBorQuery = useRef(sliderValuesFromQuery);
  const initalFetch = useRef(true);
  const [dataRequested, setDataRequested] = useState(
    location.pathname.toLowerCase() === '/megs'
      ? {
          dataset: 'megs',
          year: 2050,
          country: 'aus'
        }
      : {
          dataset: 'esx',
          year: yearFromQuery,
          country: countryFromQuery
        }
  );

  const [navRequired, setNavRequired] = useState(false);
  const [version, setVersion] = useState(versionFromQuery);
  const firstRunValues = useRef();
  const apiFetchComplete = useRef(false);
  const [contentToDisplay, setContentToDisplay] = useState(pageFromQuery);
  const [pageMode, setPageMode] = useState(
    pageFromQuery !== 'home' && pageFromQuery !== 'charts'
  );
  const [userLink, setUserLink] = useState(
    'https://modelling.energy/?' + window.location.search.substring(1)
  );
  const [MEGSFilteringParameters, setMEGSFilteringParameters] = useState([]);

  const history = useHistory();
  const reqUpdateAddressBar = useRef();
  const isLandscape = useIsLandscape();
  useHistoryHandeller({
    history,
    sliderValues,
    setSliderValues,
    dataRequested,
    setDataRequested,
    setVersion,
    setContentToDisplay,
    setPageMode,
    reqUpdateAddressBar
  });

  const initiateCalculation = useCallback(
    async (initialData, sliderValues, firstRunValues) => {
      setLoading(true);
      try {
        const results = runCalculation({
          initialData,
          sliderValues,
          firstRunValues
        });

        setCalculatedData(results);
      } catch (error) {
        setError('Calculation Failed', error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const fetchInitialData = useCallback(async ({ dataRequested }) => {
    setLoading(true);
    try {
      const initialData = await getDataFromDB({ dataRequested });

      if (
        sliderValuesFromDBorQuery.current === 'invalid' ||
        !initalFetch.current
      ) {
        sliderValuesFromDBorQuery.current = initialData.technologyData.map(
          (a) => +a.sliderStart
        );
      }
      if (initalFetch.current) {
        const { filteringParameters: tempFilteringParametersFromQuery = '{}' } =
          getQueryVariable();
        const filteringParametersFromQueryParsed = JSON.parse(
          tempFilteringParametersFromQuery
            .replaceAll('%22', '"')
            .replaceAll('%7b', '{')
            .replaceAll('%7d', '}')
            .replaceAll('%5b', '[')
            .replaceAll('%5d', ']')
        );

        setMEGSFilteringParameters(
          Object.keys(filteringParametersFromQueryParsed).reduce((acc, fp) => {
            const index = initialData.megsTechnologies.findIndex((mt) => {
              return mt.techName.toLowerCase() === fp;
            });
            if (index === -1) return acc;

            return [
              ...acc,
              {
                ...initialData.megsTechnologies[index],
                values: filteringParametersFromQueryParsed[fp]
              }
            ];
          }, [])
        );
      }
      allInputDataNEW.current = initialData;
      initalFetch.current = false;
      firstRunValues.current = { firstRun: true };

      setSliderValues(sliderValuesFromDBorQuery.current);
      apiFetchComplete.current = true;
    } catch (error) {
      setError('Get Data Failed', error);
    } finally {
    }
  }, []);

  useEffect(() => {
    updateAddressBar({
      reqUpdateAddressBar,
      setUserLink,
      sliderValues,
      dataRequested,
      version,
      contentToDisplay,
      pageMode,
      MEGSFilteringParameters
    });
  }, [
    sliderValues,
    dataRequested,
    version,
    contentToDisplay,
    pageMode,
    MEGSFilteringParameters
  ]);

  useEffect(() => {
    //Saving First Run Results
    firstRunValues.current = calculatedData.firstRunValues;
  }, [calculatedData.firstRunValues]);

  useEffect(() => {
    // Runs the calculation on Slider Update or new API Data
    if (apiFetchComplete.current === true) {
      initiateCalculation(
        allInputDataNEW.current,
        sliderValues,
        firstRunValues.current
      );
    }
  }, [initiateCalculation, sliderValues]);

  useEffect(() => {
    //Runs the API fetch
    console.log('API CALL', dataRequested);
    fetchInitialData({ dataRequested });
  }, [dataRequested, fetchInitialData]);

  useEffect(() => {
    if (error !== null) {
      // console.log('#### ERROR ####')
      // console.log('Error Message:', error)
      // console.log('sliderValues', sliderValues)
      // console.log('dataRequested', dataRequested)
      // console.log('allInputData', allInputDataNEW.current)
      // console.log('#### ERROR ####')
    }
  }, [dataRequested, error, sliderValues]);

  const handleVersionSwitch = useCallback(() => {
    if (version === 'educational') {
      setVersion('scientific');
      return;
    }
    setVersion('educational');
  }, [setVersion, version]);

  const siteConditionalStyle = () => {
    let backgroundImage = 'linear-gradient(310deg, #b6d7ff 23%, #004d80 73%)';
    if (dataRequested.dataset === 'megs')
      backgroundImage = 'linear-gradient(333deg, #c95766 23%, #800000 59%)';
    if (navRequired && isMobile && pageMode && !isTablet)
      return {
        backgroundImage,
        height: '100vh',
        overflowY: 'hidden',
        paddingTop: '350px'
      };
    if (isMobile && pageMode && !isTablet)
      return { backgroundImage, height: '100vh', overflowY: 'hidden' };
    if (navRequired && isMobile && !isTablet)
      return { backgroundImage, paddingTop: '350px' };
    if (!isMobile && pageMode) return { backgroundImage, overflowY: 'hidden' };
    return { backgroundImage };
  };
  if (contentToDisplay === 'home') return <p>Old Main</p>;
  if (loading)
    return <div className={styles.preLoadMessage}>Loading data...</div>;
  if (error !== null)
    return <div className={styles.preLoadMessage}>Error: {error}</div>;

  const isMegs = dataRequested.dataset === 'megs';
  return (
    <AllInputDataContext.Provider value={allInputDataNEW.current}>
      <CalculatedDataContext.Provider value={calculatedData}>
        <SetSliderValuesContext.Provider value={setSliderValues}>
          <SliderValuesContext.Provider value={sliderValues}>
            <UserLinkContext.Provider value={userLink}>
              {isMobile && !isTablet ? (
                <Nav
                  navRequired={navRequired}
                  setNavRequired={setNavRequired}
                  navWidth={'100%'}
                  version={version}
                  setContentToDisplay={setContentToDisplay}
                  setPageMode={setPageMode}
                  handleVersionSwitch={handleVersionSwitch}
                  country={dataRequested.country}
                  dataset={dataRequested.dataset}
                  setDataRequested={setDataRequested}
                />
              ) : (
                ''
              )}
              <div className={styles.site} style={siteConditionalStyle()}>
                <Controls
                  navRequired={navRequired}
                  setNavRequired={setNavRequired}
                  setDataRequested={setDataRequested}
                  dataRequested={dataRequested}
                  version={version}
                  setContentToDisplay={setContentToDisplay}
                  setPageMode={setPageMode}
                  handleVersionSwitch={handleVersionSwitch}
                  MEGSFilteringParameters={MEGSFilteringParameters}
                  setMEGSFilteringParameters={setMEGSFilteringParameters}
                />

                <div
                  className={styles.pageContainerGeneric}
                  style={pageMode ? { top: '0px' } : {}}
                >
                  <GenericPage
                    setPageMode={setPageMode}
                    contentToDisplay={contentToDisplay}
                    country={dataRequested.country}
                  />
                </div>
                <div
                  className={styles.pageContainerCharts}
                  style={{
                    top: pageMode ? '100vh' : '0px',
                    display:
                      isMegs && !isLandscape && isMobile ? 'none' : 'block'
                  }}
                >
                  {isMegs && (
                    <MEGSCharts
                      MEGSFilteringParameters={MEGSFilteringParameters}
                    />
                  )}

                  {!isMegs && <MECharts version={version} />}
                </div>
              </div>
            </UserLinkContext.Provider>
          </SliderValuesContext.Provider>
        </SetSliderValuesContext.Provider>
      </CalculatedDataContext.Provider>
    </AllInputDataContext.Provider>
  );
};

// App.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'Apps'
// }

export default Main;
