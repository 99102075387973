import { faArrowRight, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import styles from "./Publications.module.css";
import { publicationsData } from "./publicationsData";
export const Publications = () => {
  const history = useHistory();
  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      window.open(`${hash}`, "_self");
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div id="publications" className={styles.container}>
      <Link to="/" className={styles.homeIcon}>
        <FontAwesomeIcon icon={faHome} />
      </Link>

      <img
        src={"/images/landingPage/publicationsBackground.webp"}
        alt=""
        className={styles.backgroundImage}
      />
      <div className={styles.contentContainer}>
        <h1>Publications</h1>
        {isMobile && <p>Scroll down for details on each publication</p>}
        <div
          className={
            !isMobile ? styles.publicationsTop : styles.publicationsTopMobile
          }
        >
          {publicationsData.map((pub) => (
            <div onClick={() => window.open(`#${pub.title}`, "_self")}>
              <img
                height={!isMobile ? "200px" : "150px"}
                src={`/publications/thumbnails/${pub.thumbnail}`}
                alt="Decarbonised Electricity Report"
                className={styles.thumbnail}
              />
            </div>
          ))}
        </div>

        {publicationsData.map((publication) => (
          <div id={publication.title} className={styles.publicationsList}>
            <div className={styles.imgContainer}>
              <img
                onClick={() =>
                  window.open(`/publications/pdf/${publication.url}`)
                }
                height="300px"
                src={`/publications/thumbnails/${publication.thumbnail}`}
                alt="Decarbonised Electricity Report"
                className={styles.thumbnail}
              />
            </div>
            <div className={styles.textContainer}>
              <h2
                onClick={() =>
                  window.open(`/publications/pdf/${publication.url}`)
                }
              >
                {publication.title}
              </h2>

              {publication.description.split("\n").map((str) => (
                <p>{str}</p>
              ))}

              <h3
                className={styles.readMore}
                onClick={() =>
                  window.open(`/publications/pdf/${publication.url}`, "_blank")
                }
              >
                Read More{"  "}
                <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
              </h3>
              {publication.secondaryLinkUrl && publication.secondaryLinkText && (
                <h3
                  className={styles.readMore}
                  onClick={() => history.push(publication.secondaryLinkUrl)}
                >
                  {publication.secondaryLinkText}
                  {"  "}
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faArrowRight}
                  />
                </h3>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
