import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { round } from 'mathjs'
import React, { memo, useRef, useState, useContext } from 'react'
import { UserLinkContext } from '../../../../config'
import styles from './SocialPopUp.module.css'

const SocialPopUp = ({ userScore, optionsData, setUserScorePopup }) => {
  const userLinkRef = useRef(null)

  const [copySuccess, setCopySuccess] = useState('Copy Link')
  const alertLevel = 'alertLevel' + round(((100 - userScore) * 4.9999) / 100)
  const userLink = useContext(UserLinkContext)
  const copyToClipboard = event => {
    userLinkRef.current.select()
    document.execCommand('copy')
    event.target.focus()
    setCopySuccess('Copied!')
    setTimeout(function () {
      setCopySuccess('Copy Link')
    }, 3000)
  }
  const facebook = () => {
    userLinkRef.current.select()
    document.execCommand('copy')
    window.open(
      'https://www.facebook.com/permalink.php?story_fbid=164044968847504&id=106193661299302',
      '_blank'
    )
  }

  const linkedIn = () => {
    userLinkRef.current.select()
    document.execCommand('copy')
    window.open(
      'https://www.linkedin.com/feed/update/urn:li:activity:6760007476130676736',
      '_blank'
    )
  }
  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.outerFrame}
          onClick={() => setUserScorePopup(false)}
        ></div>
        <div className={styles.popupContainer}>
          <div
            className={styles.popupContentContainer}
            style={{
              backgroundColor: optionsData[alertLevel]
            }}
          >
            <FontAwesomeIcon
              className={styles.close}
              icon={faTimes}
              onClick={() => setUserScorePopup(false)}
            />
            <h1>Well Done!</h1>
            <h3>You have reached a score of</h3>
            <h1 className={styles.score}>{userScore}%</h1>
            <h3>Checkout the leader board online</h3>
            <div className={styles.leaderBoardLink}>
              <button onClick={facebook}>on Facebook</button>{' '}
              <button onClick={linkedIn}>on LinkedIn</button>
            </div>
            <p>
              <strong>
                We would love to share your results and see how others have done
                <br />
                (see how others have done to improve your score)
              </strong>
            </p>
            <div className={styles.userLink}>
              <input
                ref={userLinkRef}
                value={userLink}
                type='text'
                autofocus='autofocus'
                onFocus={event => event.target.select()}
              />
              <button onClick={copyToClipboard}> {copySuccess}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialPopUp
