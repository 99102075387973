import {
  faChalkboardTeacher,
  faChartArea
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { useHistory } from "react-router-dom";
import styles from "./ESXSegment.module.css";

export const ESXSegment = memo(() => {
  let history = useHistory();

  return (
    <div id="ESx" className={styles.container}>
      {/* <div className={styles.customShapeDividerTop}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 0L0 0 598.97 114.72 1200 0z"
            className={styles.shapeFill}
          ></path>
        </svg>
      </div> */}
      <img
        src={"/images/landingPage/ME.webp"}
        alt=""
        className={styles.backgroundImage}
      />
      <h1>Electricity Scenario Explorer</h1>
      <div className={styles.contentContainer}>
        <div className={styles.imgContainer}>
          <img
            src={"/images/landingPage/MEOverview.webp"}
            alt=""
            className={styles.img}
          />
        </div>

        <div className={styles.textContent}>
          <h1>Getting Started</h1>
          <p>
            The model has 2 modes, Educational{" "}
            <FontAwesomeIcon icon={faChalkboardTeacher} /> and Scientific{" "}
            <FontAwesomeIcon icon={faChartArea} />. Currently 2 countries are
            supported, UK and Australia. Select a flag or mode icon below to get
            started.
          </p>
          <div className={styles.controlFlags}>
            <img
              onClick={() =>
                history.push({
                  pathname: "/ESx",
                  search:
                    "?page=charts&version=educational&country=uk&year=2020"
                })
              }
              src="/images/uk-flag.png"
              className={styles.flag}
              alt="United Kingdom Flag"
            />
            <div className={styles.icons}>
              {" "}
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                className={styles.icon}
                onClick={() =>
                  history.push({
                    pathname: "/ESx",
                    search:
                      "?page=charts&version=educational&country=uk&year=2020"
                  })
                }
              />
              <FontAwesomeIcon
                icon={faChartArea}
                className={styles.icon}
                onClick={() =>
                  history.push({
                    pathname: "/ESx",
                    search:
                      "?page=charts&version=scientific&country=uk&year=2020"
                  })
                }
              />
            </div>
          </div>
          <div className={styles.controlFlags}>
            <img
              onClick={() =>
                history.push({
                  pathname: "/ESx",
                  search:
                    "?page=charts&version=educational&country=aus&year=2020"
                })
              }
              src="/images/aus-flag.png"
              className={styles.flag}
              alt="Australian Flag"
            />
            <div className={styles.icons}>
              <FontAwesomeIcon
                onClick={() =>
                  history.push({
                    pathname: "/ESx",
                    search:
                      "?page=charts&version=educational&country=aus&year=2020"
                  })
                }
                icon={faChalkboardTeacher}
                className={styles.icon}
              />
              <FontAwesomeIcon
                onClick={() =>
                  history.push({
                    pathname: "/ESx",
                    search:
                      "?page=charts&version=scientific&country=aus&year=2020"
                  })
                }
                icon={faChartArea}
                className={styles.icon}
              />
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
});
