import React, { memo } from 'react'
import styles from './PageContentStyle.module.css'
const AboutUsContent = memo(() => {
  return (
    <div
      className={styles.contentContainer}
      style={{ borderColor: 'rgb(64, 145, 20)' }}
    >
      <p>
        <strong>Modelling References By Same Author:</strong> Andy Boston has
        been developing models of electricity systems for 35 years. Much of that
        was within a utility and commercially confidential. However work with
        Energy Research Partnership, Imperial College and more recently with
        <a
          href='https://redvector.co.uk/'
          rel='noopener noreferrer'
          target='_blank'
        >
          {' '}
          Red Vector{' '}
        </a>
        has been published. Geoff Bongers is author of{' '}
        <a
          href='https://www.powerfactbook.com/'
          rel='noopener noreferrer'
          target='_blank'
        >
          The Power Factbook{' '}
        </a>
        and director of
        <a
          href='https://www.gamma-energy-technology.com.au/index.html'
          rel='noopener noreferrer'
          target='_blank'
        >
          {' '}
          Gamma Energy Technologies{' '}
        </a>
        , which provides a fresh, independent and innovative consulting service,
        specialising in power generation. Geoff and Andy have collaborated
        closely over the past 3 years to develop MEGS and use it to model the
        total system cost (TSC) for decarbonising the UK and Australian
        electricity markets.
        <br />
        <br />
        <strong>Citation:</strong> Please use the following information when
        citing this website:
        <br />
        <strong>Corporate Authors:</strong>
        <a
          href='https://redvector.co.uk/'
          rel='noopener noreferrer'
          target='_blank'
        >
          {' '}
          Red Vector,{' '}
        </a>
        <a
          href='https://www.gamma-energy-technology.com.au/'
          rel='noopener noreferrer'
          target='_blank'
        >
          {' '}
          Gamma Energy Technology,{' '}
        </a>
        <a
          href='https://dab-online.co.uk/'
          rel='noopener noreferrer'
          target='_blank'
        >
          {' '}
          DAB Online{' '}
        </a>
        <br />
        URL:
        <a
          href='https://modelling.energy'
          rel='noopener noreferrer'
          target='_blank'
        >
          {' '}
          https://modelling.energy{' '}
        </a>{' '}
        <br />
        Algorithm:{' '}
        <a
          href='https://github.com/dominicboston/ModellingEnergyAlgorithm'
          rel='noopener noreferrer'
          target='_blank'
        >
          {' '}
          onGithub{' '}
        </a>
        <br />
        Name: Modelling Energy (UK {'&'} AUS Model)
        <br />
        Version: 4.0
        <br />
        Publication Date: 22nd January 2021
      </p>
    </div>
  )
})

export default AboutUsContent
