import React, { useContext, memo } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

import {
  generationChartOption,
  generationChartData
} from './annualGenerationChartData';
import { AllInputDataContext, CalculatedDataContext } from '../../../config';
import styles from './../AllCharts.module.css';

Chart.register(annotationPlugin);

const AnnualGenerationChart = memo(() => {
  const allInputData = useContext(AllInputDataContext);
  const { annualConst } = useContext(CalculatedDataContext);

  const chartData = generationChartData({ annualConst, allInputData });
  const chartOptions = generationChartOption({ annualConst });

  return (
    <div className={styles.dataChart}>
      <div className={styles.chartInnerCard}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
});
export default AnnualGenerationChart;
