import { memo, useState, useEffect } from 'react';

import FooterControls from './FooterControls';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';
import Nav from '../nav/Nav';
import { MEControls } from './MEControls';
import styles from './Controls.module.css';
import { ControlHeader } from './ControlHeader';
import { MEGSControls } from './MEGSControls';
import { useIsLandscape } from '../../useEffects/useIsLandscape';
const Controls = memo(
  ({
    setDataRequested,
    dataRequested,
    navRequired,
    setNavRequired,
    version,
    setContentToDisplay,
    setPageMode,
    handleVersionSwitch,
    MEGSFilteringParameters,
    setMEGSFilteringParameters
  }) => {
    const backgroundImage = `url("/images/${dataRequested.country}-map.png")`;
    const [incrementAmmount, setIncrementAmmount] = useState({
      incrementBy: 1
    });

    const isLandscape = useIsLandscape();

    const isMegs = dataRequested.dataset === 'megs';

    return (
      <>
        {isBrowser || isTablet ? (
          <Nav
            navRequired={navRequired}
            setNavRequired={setNavRequired}
            navWidth={isTablet ? '294px' : '359px'}
            version={version}
            handleVersionSwitch={handleVersionSwitch}
            setContentToDisplay={setContentToDisplay}
            setPageMode={setPageMode}
            country={dataRequested.country}
            dataset={dataRequested.dataset}
            setDataRequested={setDataRequested}
          />
        ) : (
          ''
        )}

        <div
          className={styles.controlWrapper}
          style={
            navRequired && (isBrowser || isTablet)
              ? {
                  overflowY: 'hidden',
                  paddingTop: '350px'
                }
              : {
                  display: isLandscape && isMegs && isMobile ? 'none' : 'block'
                }
          }
        >
          <div
            className={styles.controlContainer}
            style={{
              backgroundImage,
              height: !isMegs && isMobile ? '300px' : '100%'
            }}
          >
            <ControlHeader
              setNavRequired={setNavRequired}
              handleVersionSwitch={handleVersionSwitch}
              dataRequested={dataRequested}
              version={version}
            />
            {isMegs ? (
              <MEGSControls
                MEGSFilteringParameters={MEGSFilteringParameters}
                setMEGSFilteringParameters={setMEGSFilteringParameters}
              />
            ) : (
              <>
                <MEControls incrementAmmount={incrementAmmount} />
                <FooterControls
                  setDataRequested={setDataRequested}
                  dataRequested={dataRequested}
                  incrementAmmount={incrementAmmount}
                  setIncrementAmmount={setIncrementAmmount}
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
);

// Controls.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'Menu'
// }

export default Controls;
