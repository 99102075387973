export default function getQueryVariable () {
  let userQuery = {}
  try {
    let query = window.location.search.substring(1)
    if (query !== '') {
      // console.log('query', query) //"app=article&act=news_content&aid=160990"
      let vars = query.split('&')
      // // console.log('vars', vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=')
        if (!isNaN(pair[1])) {
          // // console.log(pair[1])
          pair[1] = parseFloat(pair[1])
        } else if (pair.length > 1) {
          pair[1] = pair[1].toLowerCase()
        }

        userQuery[pair[0]] = pair[1]
      }
      if (userQuery.sliderValues !== undefined) {
        userQuery.sliderValues = decodeURIComponent(userQuery.sliderValues)
          .split(',')
          .map(a => parseFloat(a))
        userQuery.sliderValues.splice(8, 0, 99)
        // // console.log(userQuery.sliderValues)
      }

      return userQuery
    }
  } catch {
    return 'invalid'
  }
  return 'invalid'
}
